import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import { isLoggedIn, handleSignup } from "../account/services/auth";
import { navigate, Link } from "gatsby";

const ListDiv = styled.div`
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  ol {
    padding: 0;
  }
`;

const StyledP = styled.p`
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
`;

const StyledButton = styled.button`
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgb(255, 61, 48),
    rgb(255, 43, 97)
  );
  padding: 10px 50px;
  border-radius: 5px;
  border-style: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 1.5rem auto;
  margin-bottom: 0.5rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:focus {
    outline: 0;
  }
`;

const LinkP = styled(Link)`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }
`;

const StyledH3 = styled.h3`
  font-family: "Roboto", serif;
`;

export default class About extends React.Component {
  render() {
    return (
      <Layout>
        <StyledH3>Q&A</StyledH3>
        <ListDiv>
          <ol>
            <li>
              <p>How does your money back guarantee work?</p>
              <p>
                If you are not 100% satisfied within the first 7 days, email us
                and we&apos;ll give you a full refund. For information on our
                pricing please scroll down to{" "}
                <StyledA href="#subscription-details">
                  Subscription Details
                </StyledA>
                .
              </p>
            </li>
            <li>
              <p>What weight metrics do you upload to each destination?</p>
              <details style={{ marginBottom: "8px" }}>
                <summary>Garmin Connect</summary>
                We upload weight, body fat %, body water %, skeletal muscle
                mass, bone mass, and a timestamp (when possible - your scale
                might not provide all of these values).
              </details>
              <details style={{ marginBottom: "8px" }}>
                <summary>TrainerRoad/Strava/Concept2/Peloton</summary>
                We only upload weight. The other metrics are not supported by
                TrainerRoad, Strava, Concept2 Logbook, and Peloton.
              </details>
              <details style={{ marginBottom: "8px" }}>
                <summary>Zwift</summary>
                Zwift will pair natively with your Withings or Fitbit scale. If
                you don't have a Withings or Fitbit scale you can use Smart
                Scale Sync to get your weight into Zwift by connecting Fitbit as
                a destination (in SmartScaleSync) and then pairing Zwift with
                Fitbit (from within Zwift). Zwift does not support body fat or
                any advanced weight metrics, it only accepts weight.
              </details>
              <details style={{ marginBottom: "8px" }}>
                <summary>Fitbit</summary>
                We upload weight, body fat %, and a timestamp for the weigh in
                (when possible - your scale might not provide all of these
                values). Other metrics are not supported by Fitbit.
              </details>
              <details style={{ marginBottom: "8px" }}>
                <summary>Polar Flow</summary>
                We upload weight and a timestamp for the weigh in. Other metrics
                are not supported by Polar Flow.
              </details>
              <details style={{ marginBottom: "8px" }}>
                <summary>Withings</summary>We are not able to upload weight to
                Withings (the Withings API does not support this functionality).
                You can only use Withings as a source of weight data, you can
                not use it as a destination for weight data.
                <br />
              </details>{" "}
            </li>
            <li>
              <p>
                My weight source or destination is not supported, can you add
                it?{" "}
              </p>
              <p>
                There are several scales which don{"'"}t connect directly to
                SmartScaleSync but they are still compatible because they
                connect with Fitbit. For example, the Renpho scale can auto-sync
                with Fitbit, and then you can set Fitbit to be your weight
                source in SmartScaleSync.
              </p>
              <p>
                <StyledA href="https://tally.so/r/nWPkLm" target="_blank">
                  If your weight source is not supported you can submit a
                  request here.
                </StyledA>
              </p>
              <p>
                <StyledA href="https://tally.so/r/w7og6w" target="_blank">
                  If your weight destination is not supported you can submit a
                  request here.
                </StyledA>
              </p>
            </li>
            <li>
              <p>
                How long will it take for my measurement to sync after I&apos;ve
                weighed myself?
              </p>
              <p>
                Typically the service will sync new measurements within 5
                minutes. If weights are not syncing we are likely already aware
                of the issue and looking into it, but feel free to reach out to
                us at support@smartscalesync.com. We also have a{" "}
                <StyledA href="https://twitter.com/smartscalesync">
                  twitter feed
                </StyledA>{" "}
                where we post information regarding any outages.
              </p>
            </li>
            <li id="credentials">
              <p>
                Why do you need my credentials and how are these kept secure?
              </p>
              <p>
                Garmin*, TrainerRoad, Concept2, Peloton, and Polar Flow do not
                provide a free API for 3rd party developers that allows weight
                uploads. We protect your credentials (password & username)
                within commercially acceptable means to prevent loss and theft,
                as well as unauthorised access, disclosure, copying, use or
                modification. Please refer to our{" "}
                <LinkP to="/privacy-policy/">Privacy Policy</LinkP> &{" "}
                <LinkP to="/terms-of-service/">Terms of Service</LinkP> for
                official and legally binding details. Your credentials are
                captured securely within a page running no 3rd party javascript
                hosted on a different domain (served within an iframe). They are
                then encrypted with AES-192, using a cipher that is further
                encrypted via a key managed through AWS Key Management Service.
                Your encrypted credentials are stored within Stripe customer
                metadata (where they are likely encrypted again by Stripe). Your
                encrypted credentials are fetched and decrypted within a
                temporarily provisioned container (AWS Lambda) only when a
                weight upload is triggered.
              </p>
              <p>
                * We do not require your Garmin credentials if you set Garmin as
                your source. Garmin credentials are only needed when it is
                configured as a destination.
              </p>
            </li>
            <li id="suspicious-activity">
              <p>
                Garmin sent me an email saying that someone signed-in to my
                account from a new location.
              </p>
              <p>
                SmartScaleSync signs-in on your behalf in order to upload your
                weighins (and *only* to upload weighins). This can trigger
                "suspicious activity" emails from Garmin.
              </p>
              <p>
                {" "}
                If you login to SmartScaleSync and head to the settings page,
                you will find a timestamp from the last time SmartScaleSync
                attempted to login to your account. This is not the timestamp of
                your last weighin, it's the timestamp of the most recent attempt
                to sync a weighin. Sometimes a sync will fail and it will be
                retried several hours after the weighin occured.
              </p>
              <p>
                If you are worried that someone else might have accessed your
                account you should absolutely reset your Garmin password. But be
                sure to update it in SmartScaleSync or else the weighins can't
                be uploaded.
              </p>
            </li>
            <li>
              <p>
                My weighins stopped syncing with
                Garmin/TrainerRoad/Polar/Peloton/Concept2.
              </p>
              <p>
                Did you recently change your password for that service? If so
                you will have to update it in SmartScaleSync, otherwise the
                service will not be able to login to your account to upload your
                weighins. If not, send us an email support@smartscalesync.com
                and we can take a closer look.
              </p>
            </li>
            <li>
              <p>
                Will I be able to unlink my fitness accounts from Smart Scale
                Sync?
              </p>
              <p>
                Yes. You can unconnnect your accounts at anytime from the
                settings page of SmartScaleSync. When you perform these actions
                your encrypted API keys/credentials are deleted immediately.
              </p>
            </li>
            <li>
              <p>Do you sync my weight measurements from the past?</p>
              <p>
                Our subscription service will only sync measurements taken after
                your subscription has started and fitness accounts have been
                connected. However, if you would like to sync your full weigh-in
                history from Withings or Fitbit to Garmin, we now offer this as{" "}
                <StyledA href="https://buy.stripe.com/7sI3dQeencSW2qI000">
                  a separate serivce for $34.99
                </StyledA>
                . Please make sure you have already created a SmartScaleSync
                account before purchasing the history sync. We do not (yet)
                offer a full history sync between other platforms and Garmin but
                shoot us an email with what you are looking for.
              </p>
            </li>
            <li>
              <p>
                I signed up and connected my accounts but my new weigh-ins are
                not syncing to Garmin.
              </p>
              <p>
                First make sure you have connected the right accounts. Some
                users have had multiple Garmin accounts and have mistakenly
                connected the wrong one.
              </p>
              <p>
                Next make sure that you have agreed to Garmin{"'"}s Terms of
                Service. Sometimes Garmin will prevent weight uploads if you
                have not agreed to their terms. You should log into your Garmin
                account and go to{" "}
                <StyledA href="https://connect.garmin.com/modern/import-data">
                  https://connect.garmin.com/modern/import-data
                </StyledA>
                . If you haven{"'"}t agreed to their terms there will be a popup
                there telling you to agree to them.
              </p>
              <p>
                If you are still having issues please contact us at
                support@smartscalesync.com.
              </p>
            </li>
            <li>
              <p>
                My weigh-ins are uploading to Garmin but they are missing BMI.
              </p>
              <p>
                Garmin calculates BMI for each weighin using your height that is
                stored in Garmin Connect. If your BMI is not showing up, it
                usually means you need to set your height in Garmin Connect.
                When available, SmartScaleSync will upload BMI from
                Withings/Fitbit. You should also make sure those services have
                your height.
              </p>
              <p>
                If you are still having issues after setting your height please
                contact us at support@smartscalesync.com.
              </p>
            </li>
            <li>
              <p>
                Can I configure multiple platforms to be my weight source at the
                same time (for example: Garmin and Withings)?
              </p>
              <p>
                No, you can only designate one platform to be a weight source at
                a time.
              </p>
            </li>
            <li>
              <p>
                Do you offer family-pricing for multiple users that share a
                scale?
              </p>
              <p>
                No, but you can{" "}
                <StyledA href="https://tally.so/r/3yxr6m" target="_blank">
                  submit a request here
                </StyledA>{" "}
                and we will let know you if we ever add a family plan.
              </p>
            </li>
          </ol>
        </ListDiv>
      </Layout>
    );
  }
}
